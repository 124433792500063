export default {
  rencai:{
    title:'智慧物联解决方案',
    subtitle:'制定不同设备标准化规范、横向拉通硬件产品共性，提升一体化管理能力，<br/>加强软硬件产品应用场景的融合，实现产品价值提升，双向促进软件与硬件产品用户粘性提高。',
    challenge:[{
      icon:require('../assets/image/solution/rencai_icon1.png'),
      text:'设备孤岛现象严重',
      subtext:'社区各类设备都有独立运行的系统，使用物联云端进行集中式管控，联动各系统。',
    },{
      icon:require('../assets/image/solution/rencai_icon2.png'),
      text:'人工巡检费时费力',
      subtext:'通过智能传感设备，使设备设施实时在线，24小时秒级巡检。',
    },{
      icon:require('../assets/image/solution/rencai_icon3.png'),
      text:'设备维保计划全凭经验',
      subtext:'设备历史运行数据为维养计划做依据，制定科学合理预算。',
    },{
      icon:require('../assets/image/solution/rencai_icon4.png'),
      text:'维修保养效率太低',
      subtext:'智能巡检联动工单，提高综合预警能力，形成工单时效管控。',
    }],
    programs:[{
      title:'IoT物联网平台',
      desc:'快速的设备接入能力，构建人行、车行、安防、EBA等场景，较低成本投入，打破品牌壁垒，充分利旧。'
    },{
      title:'EBA远程设备监控系统',
      desc:'针对设备房、供配电、给排水、消防、电梯等设备进行运行监测，定制告警，形成预警工单，可对水、电进行分析，数据导出。'
    },{
      title:'智慧人行系统',
      desc:'充分解决社区、园区、写字楼等内外部人员通行问题，并提供人脸、二维码、刷卡、健康码等多种通行方式，对进出人员线上管理，实时查询，保证出入安全。'
    },{
      title:'智慧IOC大屏',
      desc:'IOC大屏通过大数据让运营者掌控各个细胞单元的运行状态，实现对园区全要素的感知、预防与追溯，提供掌握全局态势、统一资源调度的能力。'
    }],
    info:'围绕街道、园区、社区等空间的人行、车行、安防、水电等设备设施，构建智慧物联平台，打通人、设备、空间和服务，实现空间数字化。',
    blueimg:require('../assets/image/solution/lantu.png')
  },
  shangye:{
    title:'智慧区镇',
    subtitle:'面向街道、乡镇的基层工作、创城创卫、共建共治的核心需求，结合AI、IoT、大数据等相关技术，<br/>提供社区综合治理、服务工单引擎、社区信息中台等应用,以满足日益精细化的基层治理要求。',
    challenge:[{
      icon:require('../assets/image/solution/shangye_icon1.png'),
      text:'村委：住户采集线下且对社区安全治理无掌控',
      subtext:'使用系统采集，避免额外工作，加装人行与AI视频监控，加强人行车行安全，提升社会治理。'
    },{
      icon:require('../assets/image/solution/shangye_icon2.png'),
      text:'区镇：社区安防无管控手段，空间数据孤岛',
      subtext:'社区智能化统一平台，实现功能闭环、数据互联互通，利用大数据将社区安防数据推送至区指挥中心，制定统一的事件全流程管控。'
    },{
      icon:require('../assets/image/solution/shangye_icon3.png'),
      text:'社区中心：非户籍住户无覆盖，老人无管理',
      subtext:'利用智能设备如水电表、传感器监测独居空巢老人状态，提供平台数据采集能力，实现基础数据全覆盖与共享。'
    },{
      icon:require('../assets/image/solution/shangye_icon4.png'),
      text:'物业：线上平台不完善，人行车行未有效控制',
      subtext:'加装人行设备，进行住户测温、健康码等识别管理，利用信息化管理平台对线下人行车行设备进行云装管理，提高小区安全。'
    }],
    programs:[{
      title:'智慧区镇IOC系统',
      desc:'提供区（镇）级全景运行视图，包含公共安全、公共管理、公共服务三大类数据， 可快速直观的了解到各社区运行状态，实现数据汇聚统一，为精准决策提供有效依据。通过统一监控，实现实时识别潜在风险，快速制定解决方案，实现统一指挥调度'
    },{
      title:'智慧社区应用赋能中台',
      desc:'利用运维支撑平台、大数据支撑平台，将人口和社区数据的进一步完善，实时掌握全社区运营的总态势，提升村（居）委等基层组织的公共管理和服务水平。通过全民共治，增强和居民互动的粘性'
    },{
      title:'微应用服务平台',
      desc:'事件随时报、问题及时了、服务随时查，通过线上线下便民服务的深度融合与智能安防设备的搭建，有效提升住户的满意度、安全感、参与感、幸福感'
    },{
      title:'物联网支撑平台',
      desc:'基于AI、物联网、大数据等技术和相关智能终端的融合，提高社区的安全管理水平，提升物业服务人员的管理效能，打造智能、安全、温暖、和谐的2.0智慧社区'
    }],
    info:'强感知、汇数据、智应用，助力街居治理"最后一百米"。',
    blueimg:require('../assets/image/solution/zhqz_lantu.png')

  },
  dichan:{
    title:'智慧物业解决方案',

    subtitle:'面向物业公司、地产公司、后勤集团公司提供总部级智慧物业解决方案,<br/>赋能合作企业快速实现由传统的规范管理能力，拓展多经服务水平，全面提升效率和客户服务质量，向智慧管理与降本增效能力的转型升级。',
    challenge:[{
      icon:require('../assets/image/solution/gongzu_icon1.png'),
      text:'人员成本逐年升高，工作效率难提升',
      subtext:'员工在线，让员工少跑路，让信息多流通，让制度线上化，用系统提升效率。'
    },{
      icon:require('../assets/image/solution/gongzu_icon2.png'),
      text:'客户体验差，用户诉求不及时',
      subtext:'为社区用户提供出行、安全、便捷的服务，同时提供社区周边商户的生活服务信息。'
    },{
      icon:require('../assets/image/solution/gongzu_icon3.png'),
      text:'智能系统落后，设备问题处理不及时',
      subtext:'设备巡检维保具体步骤与事项线上化，作业过程透明，数据可实时查询，保证工作及时完成。'
    },{
      icon:require('../assets/image/solution/gongzu_icon4.png'),
      text:'财务多本账，催缴手段太原始',
      subtext:'打通支付、电子发票、凭证，实现资金、账、证线上一体化，同时与POS设备、停车系统、充电桩系统联动，实时资金归集。'
    }],
    programs:[{
      title:'智慧客服系统（400集成呼叫指挥中心）',
      desc:'打通ERP、APP、呼叫中心等渠道客户数据，打破孤岛，形成客户全生命周期画像，形成管控式的工单池，客户诉求内部处理流程清晰、节点可追溯。'
    },{
      title:'运营服务端APP&小程序',
      desc:'一站式物业服务管理平台，集工单、工作、催缴、学习为一体，形成设备设施全生命周期管理，为员工提供赋能工具，提高工作效率和管理效能。'
    },{
      title:'住户服务端APP&小程序',
      desc:'让住户畅享社区所有服务，为住户提供随身的安全出行服务、物业服务、社区邻里互助服务、社区周边生活信息服务。'
    },{
      title:'业财一体化',
      desc:'打通支付、电子发票、凭证，实现资金、账、证线上线协同，实现业务缴费发生时订单清结算，支持总部、区域、项目三级财务分权管控体系。'
    }],
    info:'面向广泛的物业行业客户合作伙伴，提供覆盖“人、财、事、物、客、商”全模块运营管理平台，灵活定义、延展性更强、迭代更快、服务更优。',
    blueimg:require('../assets/image/solution/zwy_lantu.png')
  },
  yinhang:{
    title:'银行企业数字化解决方案',
    subtitle:'响应国家号召、落实国家政策、助力住房租赁企业发展、管控资金监管\n',
    challenge:[{
      icon:require('../assets/image/solution/lanling_icon1.png'),
      text:'缺乏“住房租赁”科技平台实践经验',
      subtext:'做saas平台逻辑复杂、人力成本高、耗费时间长。'
    },{
      icon:require('../assets/image/solution/lanling_icon2.png'),
      text:'资源匹配不及时，方案落地慢',
      subtext:'行业老兵成熟业务助力平台快速上线、多软硬件快速实施对接主力数字化转型。'
    }],
    programs:[{
      title:'全方位科技赋能住房租赁行业',
      desc:'银行数字化解决方案成熟、紧跟时政、深度研究国家发布最新相关政策，做最能落地的解决方案'
    },{
      title:'成熟的SaaS解决方案\n',
      desc:'行业经验丰富，对业务理解深刻，深谙平台类产品的落地之法，成熟业务助力平台快速上线、多软硬件快速实施对接主力数字化转型'
    },{
      title:'安全、可扩展',
      desc:'金融、财务报表类型、接口丰富，扩展性强，提升业财一体化建设效率'
    },{
      title:'BI数据统计展示',
      desc:'筛选业务开展落地时的关键数据，按业务场景进行有效区分，重要数据大屏展示并可根据业务需要设置阈值，实时了解项目运营情况'
    }],
    info:'寓信科技与银行企业在住房租赁行业数字化合作方案成熟，且紧跟时政，深度研究国家发布最新相关政策，只做高效落地的解决方案。'
  },
  xitong:{
    title:'智慧园区',
    subtitle:'向园区运营方提供涵盖园区经营管理、物业服务管理、园区风险识别、设施设备管理及智能监控的整体解决方案，<br/>以IOC运营大屏，将园区全方位管理系统的数据进行采集与分析，赋能数字化园区管理。',
    challenge:[{
      icon:require('../assets/image/solution/lanling_icon1.png'),
      text:'园区数字化基础与安全管控薄弱',
      subtext:'推进园区基础数据统一收集处理，集成园区设备设施云端监测，升级智慧园区信息化系统。'
    },{
      icon:require('../assets/image/solution/lanling_icon2.png'),
      text:'园区招商及财务处理处于传统模式',
      subtext:'利用数字平台，为园区的招商、商户动态及财务收支进行数字化管理，形成报表进行分析，提升园区的招商与运营能力。'
    },{
      icon:require('../assets/image/solution/lanling_icon3.png'),
      text:'园区运营方造血能力弱，空间利用弱',
      subtext:'配置园区运营方服务商城，通过为园区企业提供专业服务获取分佣，有助于园区的创新活力。'
    },
      {
      icon:require('../assets/image/solution/lanling_icon4.png'),
      text:'园区全景运营数字化展现缺乏',
      subtext:'整合园区设备、企业、人员、运营数据，形成IOC平台，让运营者掌握所辖园区全景运营情况，基于数据进行决策。'
    }
    ],
    programs:[{
      title:'园区驾驶舱',
      desc:'大数据中心，各种指标库和图表数据结合，快速定位分析覆盖园区的全景数据。实时定位园区经营情况和园区内企业情况，提高决策效率、政策实施落地。'
    },{
      title:'智能平台',
      desc:'打造智能楼宇、智能安防、智能停车、智能门禁，形成智能化平台，掌握园区人行车行安防全方位的服务安全。'
    },{
      title:'数字平台',
      desc:'通过产品化、SaaS化服务模式，帮助产业园区在企业招商、日常运营、物业管理、资产管理、财账管理、办公协同、企业服务、员工服务、数据安全等领域实现“一站式、全场景、全链路”的数字化跃迁。'
    },
      {
      title:'服务平台',
      desc:'“服务平台+交易平台+分佣系统”服务全覆盖，园区+企业双赢，为企业提供商标注册、资质认定、信用评级、专利申请等多方位服务。'
    }
    ],
    info:'提升园区管理效能、补齐企业服务短板、创新园区运营模式。',
    blueimg:require('../assets/image/solution/zhyq_lantu.png')
  }
}
